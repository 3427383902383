const initialState = {
    loading: false,
    vouchers: [],
    categoriesFilter: [],
    materialsFilter: [],
    listUser: [],
    approveStatus: 0,
    idApprove: null,
    voucherPOS: [],
    voucherApp: [],
    voucherRedeemPoint: [],
    voucherDetail: null,
    statusSubmit: 0,
    updateStatus: 0,
    discountItems: [], //thông thường lấy từ server
    discountItemsTaiKho: [
        {
            id: 1,
            name: 'Chiết khấu 1%',
            percentage: 1,
            from: 10000000,
        },
        {
            id: 2,
            name: 'Chiết khấu 2%',
            percentage: 2,
            from: 20000000,
        },
        {
            id: 3,
            name: 'Chiết khấu 3%',
            percentage: 3,
            from: 30000000,
        },
        {
            id: 4,
            name: 'Chiết khấu 4%',
            percentage: 4,
            from: 50000000,
        },
        {
            id: 6,
            name: 'Chiết khấu 6%',
            percentage: 6,
            from: 100000000,
        },
        {
            id: 625,
            name: 'Chiết khấu 6.25%',
            percentage: 6.25,
            from: 150000000,
        },
        {
            id: 65,
            name: 'Chiết khấu 6.5%',
            percentage: 6.5,
            from: 200000000,
        },
        {
            id: 7,
            name: 'Chiết khấu 7%',
            percentage: 7,
            from: 250000000,
        },
        {
            id: 75,
            name: 'Chiết khấu 7.5%',
            percentage: 7.5,
            from: 300000000,
        },
        {
            id: 8,
            name: 'Chiết khấu 8%',
            percentage: 8,
            from: 350000000,
        },
        {
            id: 85,
            name: 'Chiết khấu 8.5%',
            percentage: 8.5,
            from: 400000000,
        },
        {
            id: 9,
            name: 'Chiết khấu 9%',
            percentage: 9,
            from: 500000000,
        }
    ],
    discountItemsPrePaid: [
        {
            id: 2,
            name: 'Chiết khấu 2%',
            percentage: 2,
            from: 10000000,
        },
        {
            id: 25,
            name: 'Chiết khấu 2.5%',
            percentage: 2.5,
            from: 250000000,
        }
    ],
    statusCreateVoucherOrder: 0,
    statusUpdateVoucherOrder: 0,
    idCreated: null,
}

const voucherReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_VOUCHER_ORDER_SUCCEEDED":
            return {
                ...state,
                loading: false,
                statusUpdateVoucherOrder: 1,
            }
        case "UPDATE_VOUCHER_ORDER_FAILED":
            return {
                ...state,
                loading: false,
                statusUpdateVoucherOrder: 2,
            }

        case "SET_ID_CREATED_NULL":
            return {
                ...state,
                idCreated: null,
            }
        case "CREATED_VOUCHER_ORDER_SUCCEEDED":
            return {
                ...state,
                loading: false,
                statusCreateVoucherOrder: 1,
                idCreated: action.payload.id,
            }
        case "CREATED_VOUCHER_ORDER_FAILED":
            return {
                ...state,
                loading: false,
                statusCreateVoucherOrder: 2,
            }

        case "FETCH_DISCOUNT_ITEMS_SUCCEEDED":
            return {
                ...state,
                loading: false,
                discountItems: action.payload,
            }

        case "FETCH_VOUCHER_ORDERS_SUCCEEDED":
            return {
                ...state,
                loading: false,
                vouchers: action.payload.data.result,
            }
     
        case "FETCH_APRROVE_VOUCHER_SUCCEEDED":
            return {
                ...state,
                approveStatus: 1,
                loading: false,
            }
        case "SET_ID_APPROVE": 
            return {
                ...state,
                idApprove: action.payload,
            }
        case "SET_DEFAULT_STATUS": 
            return {
                ...state,
                loading: false,
                approveStatus: 0,
                idApprove: null,
                statusSubmit: 0,
                updateStatus: 0,
                statusCreateVoucherOrder: 0,
                statusUpdateVoucherOrder: 0,
            }
        case "STATUS_VOUCHER_POS_SUCCEEDED":
            return {
                ...state,
                loading: false,
                updateStatus: 1,
            }
        case "SET_STATUS_UPDATE_REDEEM_POINT":
            return {
                ...state,
                voucherRedeemPoint: {
                    ...state.voucherRedeemPoint,
                    status: action.payload,
                }
            }
        case "SET_STATUS_UPDATE_APP":
            return {
                ...state,
                voucherDetail: {
                    ...state.voucherDetail,
                    status: action.payload,
                }
            }
        case "CREATED_VOUCHER_SUCCEEDED":
            return {
                ...state,
                loading: false,
                statusSubmit: 1,
            }
        case "FETCH_VOUCHER_POS_SUCCEEDED":
            return {
                ...state,
                loading: false,
                voucherPOS: action.payload.data.result,
            }
        case "FETCH_LIST_USER_VOUCHER_SUCCEEDED":
            return {
                ...state,
                loading: false,
                listUser: action.payload,
            }
        case "FETCH_REDEEM_POINT_SUCCEEDED":
            return {
                ...state,
                loading: false,
                voucherRedeemPoint: action.payload,
            }
        case "FETCH_VOUCHER_APP_SUCCEEDED":
            return {
                ...state,
                loading: false,
                voucherApp: action.payload,
            }
        case "FETCH_VOUCHER_DETAIL_SUCCEEDED":
            return {
                ...state,
                loading: false,
                voucherDetail: action.payload,
            }
        case "FETCH_CATEGORIES_FILTER_SUCCEEDED":
            return {
                ...state,
                loading: false,
                categoriesFilter: action.payload
            };
        case "FETCH_MATERIALS_FILTER_SUCCEEDED":
            return {
                ...state,
                loading: false,
                materialsFilter: action.payload
            }
        case "SHOW_VOUCHER_LOADING":
            return {
                ...state,
                loading: true
            }

        default: 
            return state;
    }
}

export default voucherReducer