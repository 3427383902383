const initialState = {
  loading: false,
  paymentType: [],
  serviceMode: [],
  statusTrans: [],
  statusPayment: [],
  transactions: [],
  totalPage: 0,
  dataTrans: [],
  transDetail: null,
};

const transMenagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_TRANSACTION_BY_VOUCHER_ORDER_ID_SUCCESSED":
      console.log(
        "FETCH_TRANSACTION_BY_VOUCHER_ORDER_ID_SUCCESSED ===>",
        action.payload
      );
      return {
        ...state,
        transDetail: action.payload?.detailByDate[0],
        loading: false,
      };
    case "UPDATE_STATUS_TRANSACTION_VOUCHER_ORDER":
      return {
        ...state,
        dataTrans: state.dataTrans.map((dt) =>
          dt.voucherOrderId === action.payload
            ? { ...dt, voucherOrderStatus: 9 }
            : { ...dt }
        ),
      };
    case "SET_DEFAULT_DATA_TRANS":
      return {
        ...state,
        loading: false,
        dataTrans: [],
        totalPage: 0,
      };
    case "SET_FETCH_TRANS_INFINITE_SUCCESSED":
      return {
        ...state,
        loading: false,
        dataTrans: state.dataTrans
          .concat(action.payload.data?.detailByDate)
          .filter(
            (item, index, self) =>
              index ===
              self.findIndex((t) => t.voucherOrderId === item.voucherOrderId)
          ),
        // dataTrans: action.payload.data?.detailByDate,
        totalPage: action.payload?.totalPage,
      };
    case "SET_LOADING_TRANS":
      return {
        ...state,
        loading: true,
      };
    case "SET_FETCH_TRANS_SUCCESS":
      return {
        ...state,
        loading: false,
        transactions: action.payload.data,
        totalPage: action.payload?.totalPage,
      };
    case "FETCH_ALL_PAYMENTS_TYPE":
      return {
        ...state,
        paymentType: action.payload,
      };
    case "FETCH_ALL_SERVICE_MODE":
      return {
        ...state,
        serviceMode: action.payload,
      };
    case "FETCH_ALL_STATUS_TRANS":
      return {
        ...state,
        statusTrans: action.payload,
      };
    case "FETCH_ALL_STATUS_PAYMENT":
      return {
        ...state,
        statusPayment: action.payload,
      };

    case "SET_DEFAULT_TRANSACTION":
      return initialState;
    default:
      return state;
  }
};

export default transMenagementReducer;
