const initialRequestWorkFlowModalState = {
   showRequestWorkFlowModal: false,
   modalDescription: "",
};

const initialModalState = {
   modalMaterialId: '',
   modalMaterialCode: '',
   modalMaterialName: '',
   modalMaterialBaseUom: '',
   modalBrandId: '',
   modalBrandName: '',
   modalLotNumber: '',
   modalManufacturedDate: '',
   modalExpiryDate: '',
   modalUomId: '',
   modalUomName: '',
   modalUomQuantityType: '',
   modalBaseQuantity: '',
   modalQuantity: '',
   modalBaseQuantityOnHand: '',
   modalQuantityOnHand: '',
   modalDescription: '',

   modalUsedBaseUom: false,
   modalUsedBaseUomValue: '',
   modalUsedBaseUomKey: null,

   showModal: false,
}

const initialState = {
   receivedDate: '',
   receivedType: '0',
   fromSupplier: '',
   fromWarehouse: '',
   toWarehouse: '',
   receivedBy: '',
   referenceNumber: '',
   attachedFile: null,
   AttachedFileKey: null,
   items: [],
   submitNewGoodsReceivedStatus: 0,
   updateGoodsReceivedStatus: 0,
   deleteGoodsReceivedStatus: 0,
   submitWorkFlowActionStatus: 0,
   description: '',

   selectedStockTransfer: '',
   selectedStockRequest: '',
   selectedPurchaseOrder: '',

   ...initialModalState,
   ...initialRequestWorkFlowModalState,

   comboboxItems: [],
   suppliersComboboxItems: [],
   goodsReceivedList: [],
   totalPages: 0,

   goodsReceivedDetail: '',

   modalValidationFailed: false,
   formValidationFailed: false,

   loading: false,

   showPrintAllBarcodesModal: false,
   barcodes: [],

   approveFlag: 0,
   createFlag: 0,
   deleteFlag: 0,
   editFlag: 0,
   viewFlag: 0,
}

const goodsReceivedReducer = (state = initialState, action) => {
   switch (action.type) {
      case 'SET_AUTHO_MODULE_GOODS_RECEIVEDS':
         return {
            ...state,
            createFlag: action.payload.createFlag,
            approveFlag: action.payload.approveFlag,
            deleteFlag: action.payload.deleteFlag,
            editFlag: action.payload.editFlag,
            viewFlag: action.payload.viewFlag,
         }
      case 'UPDATE_MANUFACTURED_DATE':
         const newItemsManufacturerDate = state.items.map((item, index) => index === action.payload.index ? { ...item, manufacturedDate: action.payload.manufacturedDate } : item)
         return {
            ...state,
            items: newItemsManufacturerDate
         }
      case 'UPDATE_EXPIRY_DATE':
         const newItemsExpiryDate = state.items.map((item, index) => index === action.payload.index ? { ...item, expiryDate: action.payload.expiryDate } : item)
         return {
            ...state,
            items: newItemsExpiryDate
         }
      case 'UPDATE_LOT_NUMBER_GOODS_RECEIVED_GOODS_RECEIVED':
         const newItemsLotNumber = state.items.map((item, index) => index === action.payload.index ? { ...item, lotNumber: action.payload.lotNumber } : item)
         return {
            ...state,
            items: newItemsLotNumber
         }
      case 'HIDE_PRINT_ALL_BARCODE_MODAL':
         return {
            ...state,
            showPrintAllBarcodesModal: false
         }
      case 'SHOW_ALL_BARCODES_MODAL':
         return {
            ...state,
            barcodes: action.payload,
            showPrintAllBarcodesModal: true
         }

      case 'SHOW_GOODS_RECEIVED_LOADING':
         return {
            ...state,
            loading: true
         }

      case 'CLEAR_DATA_GOODS_RECEIVED':
         return {
            ...state,
            updateGoodsReceivedStatus: 0,
            submitNewGoodsReceivedStatus: 0,
            deleteGoodsReceivedStatus: 0,
            submitWorkFlowActionStatus: 0,
            receivedDate: "",
            receivedType: "0",
            receivedBy: "",
            referenceNumber: "",
            description: "",
            attachedFile: "",
            items: []
         }
      case "SUBMIT_GOODS_RECEIVED_WORK_FLOW_SUCCESS": {
         return {
            ...state,
            submitWorkFlowActionStatus: 1,
            loading: false
         }
      }
      case 'SUBMIT_GOODS_RECEIVED_WORK_FLOW_FAILED':
         return {
            ...state,
            submitWorkFlowActionStatus: 2,
            loading: false
         }

      case 'UPDATE_MODAL_USED_BASE_UOM_GOODS_RECEIVED':
         return {
            ...state,
            modalUsedBaseUom: !state.modalUsedBaseUom,
            modalUsedBaseUomValue: state.modalUsedBaseUom == true ? '' : 'danger',
            modalBaseQuantityOnHand: '',
            modalQuantityOnHand: ''
         }

      case 'UPDATE_MODAL_VALIDATION_GOODS_RECEIVED':
         return {
            ...state,
            modalValidationFailed: action.payload
         }

      case 'UPDATE_FORM_VALIDATION_GOODS_RECEIVED':
         return {
            ...state,
            formValidationFailed: action.payload
         }

      case 'GENERATE_ORIGINAL_ITEMS_GOODS_RECEIVED':
         let originalItems = []
         let materials = action.payload
         materials && materials.forEach(item => {
            originalItems.push({
               id: item.id,
               value: item.name + ' (' + item.code + ') ',
               code: item.code,
               name: item.name,
               image: item.image,
               baseUomName: item.baseUomName
            })
         })
         return {
            ...state,
            comboboxItems: originalItems,
            modalValidationFailed: false,
            formValidationFailed: false
         }

      case 'GENERATE_SUPPLIER_COMBOBOX_ITEMS_GOODS_RECEIVED':
         let suppliersOriginalItems = []
         let suppliers = action.payload
         suppliers && suppliers.forEach(item => {
            suppliersOriginalItems.push({
               id: item.id,
               value: item.name,
            })
         })
         return {
            ...state,
            suppliersComboboxItems: suppliersOriginalItems,
            modalValidationFailed: false,
            formValidationFailed: false
         }

      case 'UPDATE_RECEIVED_DATE':
         return {
            ...state,
            receivedDate: action.payload
         }
      case 'UPDATE_FROM_SUPPLIER':
         return {
            ...state,
            fromSupplier: parseInt(action.payload)
         }
      case 'UPDATE_FROM_WAREHOUSE':
         return {
            ...state,
            fromWarehouse: parseInt(action.payload)
         }
      case 'UPDATE_TO_WAREHOUSE':
         return {
            ...state,
            toWarehouse: parseInt(action.payload)
         }
      case 'UPDATE_RECEIVED_TYPE':
         return {
            ...state,
            receivedType: action.payload,
            items: []
         }
      case 'UPDATE_RECEIVED_BY':
         return {
            ...state,
            receivedBy: action.payload
         }
      case 'UPDATE_REFERENCE_NUMBER':
         return {
            ...state,
            referenceNumber: action.payload
         }

      case 'UPDATE_DESCRIPTION_GOODS_RECEIVED':
         return {
            ...state,
            description: action.payload
         }

      case 'UPDATE_ATTACHED_FILE_GOODS_RECEIVED':
         return {
            ...state,
            attachedFile: action.payload
         }

      case 'UPDATE_MODAL_MATERIAL_GOODS_RECEIVED':
         return {
            ...state,
            modalMaterialId: action.payload.id,
            modalMaterialCode: action.payload.code,
            modalMaterialName: action.payload.name,
            modalMaterialBaseUom: action.payload.baseUom,
            // defaultBrandSelected: true,
            // defaultUomSelected: true
         }
      case 'UPDATE_MODAL_BRAND':
         return {
            ...state,
            modalBrandId: action.payload.id,
            modalBrandName: action.payload.name,
            modalUomId: '',
            modalUomName: '',
         }

      case 'UPDATE_MODAL_LOT_NUMBER_GOODS_RECEIVED':
         return {
            ...state,
            modalLotNumber: action.payload
         }
      case 'UPDATE_MODAL_UOM_GOODS_RECEIVED':
         return {
            ...state,
            modalUomName: action.payload.name,
            modalUomId: action.payload.id,
            modalUomCode: action.payload.code,
            modalUomQuantityType: action.payload.quantityType,
            // defaultUomSelected: false
         }

      case 'UPDATE_MODAL_MANUFACTURED_DATE_GOODS_RECEIVED':
         return {
            ...state,
            modalManufacturedDate: action.payload
         }

      case 'UPDATE_MODAL_EXPIRY_DATE_GOODS_RECEIVED':
         return {
            ...state,
            modalExpiryDate: action.payload
         }
      case 'UPDATE_MODAL_BASE_QUANTITY':
         return {
            ...state,
            modalBaseQuantity: action.payload
         }
      case 'UPDATE_MODAL_QUANTITY':
         return {
            ...state,
            modalQuantity: action.payload
         }
      case 'UPDATE_MODAL_BASE_QUANTITY_ON_HAND_GOODS_RECEIVED':
         return {
            ...state,
            modalBaseQuantityOnHand: action.payload
         }
      case 'UPDATE_MODAL_QUANTITY_ON_HAND_GOODS_RECEIVED':
         return {
            ...state,
            modalQuantityOnHand: action.payload
         }

      case 'INSERT_ITEM_GOODS_RECEIVED':
         let items = state.items
         items.push({
            id: state.modalMaterialId,
            code: state.modalMaterialCode,
            name: state.modalMaterialName,
            brandId: state.modalBrandId,
            lotNumber: state.modalLotNumber,
            manufacturedDate: state.modalManufacturedDate,
            expiryDate: state.modalExpiryDate,
            uomId: state.modalUomId,
            uomCode: state.modalUomCode,
            uomName: state.modalUomName,
            baseQuantity: state.modalBaseQuantityOnHand,
            quantity: state.modalQuantityOnHand,
            baseQuantityOnHand: state.modalBaseQuantityOnHand,
            quantityOnHand: state.modalQuantityOnHand
         })

         return {
            ...state,
            items
         }

      case 'HIDE_MODAL_GOODS_RECIEVED':
         return {
            ...state,
            modalValidationFailed: false,
            showRequestWorkFlowModal: false
         }

      case 'SHOW_MODAL_GOODS_RECEIVED':
         return {
            ...state,
            showModal: true,
            modalManufacturedDate: '2021-01-01',
            modalExpiryDate: '2021-12-31',
            modalLotNumber: 'LOT010621',
         }
      case "CLEAR_MODAL_GOODS_RECIEVED":
         return {
            ...state,
            modalDescription: "",
         };
      case "SHOW_REQUEST_WORK_FLOW_MODAL_GOODS_RECIEVED":
         return {
            ...state,
            showRequestWorkFlowModal: true,
         };

      case "UPDATE_REQUEST_WORK_FLOW_MODAL_DESCRIPTION_GOODS_RECIEVED": {
         return {
            ...state,
            modalDescription: action.payload,
         }
      }

      case 'SUBMIT_NEW_GOODS_RECEIVE_SUCCEEDED':
         return {
            ...state,
            submitNewGoodsReceivedStatus: 1,
            loading: false,
         }

      case 'UPDATE_GOODS_RECEIVE_SUCCEEDED':
         return {
            ...state,
            updateGoodsReceivedStatus: 1,
            loading: false,
         }

      case 'DELETE_GOODS_RECEIVE_SUCCEEDED':
         return {
            ...state,
            deleteGoodsReceivedStatus: 1,
            loading: false,
         }
         
      case 'SUBMIT_NEW_GOODS_RECEIVE_FAILED':
         return {
            ...state,
            submitNewGoodsReceivedStatus: 2,
            loading: false
         }

      case 'FETCH_GOODS_RECEIVEDS_SUCCEEDED':
         return {
            ...state,
            goodsReceivedList: action.payload,
            loading: false
         }

      case 'FETCH_GOODS_RECEIVEDS_SUCCEEDED_V2':
         return {
            ...state,
            goodsReceivedList: action.payload.data,
            totalPages: action.payload.totalPage,
            loading: false
         }

      case 'FETCH_GOODS_RECEIVED_DETAIL_SUCCEEDED':
         return {
            ...state,
            goodsReceivedDetail: action.payload,
            loading: false
         }

      case 'CLEAR_GOODS_RECEIVED_DETAILS':
         return {
            ...state,
            goodsReceivedDetail: '',
            loading: false
         }

      case 'UPDATE_SELECTED_STOCK_TRANSFER':
         return {
            ...state,
            selectedStockTransfer: action.payload,
            items: []
         }

      case 'UPDATE_SELECTED_PURCHASE_ORDER':
         return {
            ...state,
            selectedPurchaseOrder: action.payload,
            items: []
         }

      case 'UPDATE_SELECTED_STOCK_REQUEST':
         return {
            ...state,
            selectedStockRequest: action.payload,
            items: []
         }

      case 'UPDATE_ITEMS_ON_STOCK_TRANSFER_CHANGED':
         return {
            ...state,
            items: action.payload != null ? action.payload.details : [],
            toWarehouse: action.payload != null ? action.payload.forWarehouseId : ''
         }

      case 'UPDATE_ITEMS_ON_PURCHASE_ORDER_CHANGED':
         return {
            ...state,
            items: action.payload != null ? action.payload.details : [],
            // toWarehouse: action.payload != null ? action.payload.forWarehouseId : ''
         }

      case 'UPDATE_ITEMS_ON_STOCK_REQUEST_CHANGED':
         return {
            ...state,
            items: action.payload != null ? action.payload.details : [],
            //toWarehouse: action.payload != null ? action.payload.forWarehouseId : ''
         }

      case 'UPDATE_BASE_QUANTITY_ON_HAND_GOODS_RECEIVED':
         let newItems = state.items.map((item, index) => action.payload.index === index ? { ...item, baseQuantityOnHand: parseFloat(action.payload.value), quantityOnHand: '' } : item)
         return {
            ...state,
            items: newItems
         }

      case 'UPDATE_QUANTITY_ON_HAND_GOODS_RECEIVED':
         newItems = state.items.map((item, index) => action.payload.index === index ? { ...item, quantityOnHand: parseFloat(action.payload.value), baseQuantityOnHand: '' } : item)
         return {
            ...state,
            items: newItems
         }
      default:
         return state
   }
}

export default goodsReceivedReducer
